.section {
    /* background-color: #C8DDDF; */
    background-image: linear-gradient(rgba(255, 255, 255, 0.493), rgba(255, 255, 255, 0.527)), url('../../public/Fondo.jpg');
    background-size: cover;
    /* opacity: .9; */
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 91vh;
    width: 100%;

}

/* .section img {
    height: 315px;
    width: 600px;
    padding: 70px;
    top: 510px;
    border-radius: 5px;
} */

.section h2 {
    font-size: 40px;
    font-weight: 400;
    /* padding-right: 100px; */
    text-align: center;
    color: #004AAD;
    float: left;
}

@media screen and (max-width: 576px) {
    .section {
        display: flex;
        flex-direction: column;
    }

    .section img {
        height: 165px;
        width: 300px;
        padding: 50px;
    }

    .section h2 {
        font-size: 28px;
        padding: 10px 40px;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .section {
        display: flex;
        flex-direction: column;
    }

    .section img {
        height: 265px;
        width: 500px;
        padding: 50px;
    }

    .section h2 {
        font-size: 28px;
        padding: 10px 40px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .section img {
        height: 215px;
        width: 500px;
        padding: 40px;
    }

    .section h2 {
        font-size: 28px;
        padding-right: 40px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
    .section img {
        height: 265px;
        width: 550px;
    }

    .section h2 {
        font-size: 30px;
        padding-right: 80px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
    .section img {
        height: 265px;
        width: 550px;
    }

    .section h2 {
        font-size: 35px;
        padding-right: 80px;
    }
}