/****** Colores ******/
/* Fondo #224939 */
/* letra #D9D9D9 */

/****** Letra ******/
/* font-family: 'Roboto', sans-serif; */
/* Thin 100 */
/* Light 300 */
/* Regular 400 */



.footer {
    /* background-color: #3d755e; */
    background-color: #3A9A9D;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 40px;
    text-align: center;
    height: auto;
}

.footer h2 {
    font-size: 30px;
    font-weight: 400;
}

.contact {
    display: flex;
    justify-content: space-between;
}

.contact li{
    list-style: none;
    padding-left: 60px;
    text-align: right;
    font-size: 18px;
}

.reserved-right h4 {
    font-size: 12px;
    font-weight: 100;
    display: inline-block;
    align-items: center;
}


@media screen and (max-width: 576px) {
    .footer {
        text-align: center;
        flex-direction: column;
        padding: 5px 20px;
    }

    .footer h2 {
        font-size: 30px;
        padding: 20px;
    }

    .contact{
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .contact li{
        padding-bottom: 10px;
        font-size: 12px;
        text-align: center;
        margin-right: 60px;
    }
    
    .reserved-right h4 {
        text-align: center;
        font-size: 10px;
        padding-top: 15px;
    }
}


@media screen and (min-width: 576px) and (max-width: 768px) {
    .footer {
        flex-direction: column;
        padding: 5px 20px;
    }

    .footer h2 {
        font-size: 30px;
        padding: 10px;
    }

    .contact li{
        padding-left: 20px;
        font-size: 15px;
    }
    
    .reserved-right h4 {
        font-size: 10px;
        padding-top: 15px;
    }
}


@media screen and (min-width: 768px) and (max-width: 992px) {
    .footer {
        padding: 5px 15px;
    }

    .footer h2 {
        font-size: 30px;
    }

    .contact li{
        padding-left: 15px;
        font-size: 12px;
    }
    
    .reserved-right h4 {
        font-size: 10px;
        padding-top: 25px;
    }
}


@media screen and (min-width: 992px) and (max-width: 1024px) {    
    .footer h2 {
        font-size: 25px;
    }

    .contact li{
        padding-left: 30px;
        font-size: 16px;
    }
    
    .reserved-right h4 {
        font-size: 10px;
        padding-top: 40px;
    }
}


@media screen and (min-width: 1024px) and (max-width: 1280px) {    
    .footer h2 {
        font-size: 35px;
    }

    .contact li{
        padding-left: 30px;
        font-size: 17px;
    }
    
    .reserved-right h4 {
        font-size: 10px;
        padding-top: 25px;
    }
}